@font-face {
  font-family: "Gotham-medium";
  src: url("../assets/fonts/GothamMedium.ttf");
}

@font-face {
  font-family: "Myriad Pro";
  src: url("../assets/fonts/MyriadPro-Regular.otf");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  src: url("../assets/fonts/MyriadPro-Semibold.otf");
}

@font-face {
  font-family: "Gotham Rounded Bold";
  src: url("../assets/fonts/Gotham\ Rounded\ Bold.otf");
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.MuiDrawer-paperAnchorRight {
  background-color: #e54416 !important;
  width: 25%;
}

@media (max-width: 768px) {
  .MuiDrawer-paperAnchorRight {
    width: 80%;
  }

}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 6%;

}

// .loader {
//   position: relative;
//   display: flex;
// }

// .loader span {
//   position: relative;
//   width: 200px;
//   height: 200px;
//   background-color: #eaeef0;
//   border: 6px solid #eaeef0;
//   border-radius: 50%;
//   box-shadow: -8px -8px 15px rgba(255, 255, 255, 1),
//   8px 8px 25px rgba(255, 255, 255, 0.2);
//   overflow: hidden;
// }

// .loader span::before {
//   content: "";
//   position: absolute;
//   inset: 0;
//   border-radius: 50%;
//   box-shadow: inset 10px 10px 20px rgba(0, 0, 0, 0.5),
//   inset -5px -5px 15px rgba(255, 255, 255, 1);
// }

// .loader span::after {
//   content: "";
//   position: absolute;
//   inset: 40px;
//   background: #eaeef0;
//   border: 3px solid #eaeef0;
//   border-radius: 50%;
//   box-shadow: -8px -8px 25px rgba(255, 255, 255, 1),
//   8px 8px 25px rgba(0, 0, 0, 0.25),
//   inset 3px 3px 10px rgba(0, 0, 0, 0.15),
//   inset -1px -1px 15px rgba(255, 255, 255, 1);
// }

// .loader span i {
//   position: absolute;
//   inset: 0;
//   border-radius: 50%;
//   background: linear-gradient(#E54416, #FF931E, #ffeb3b);
//   animation: animateLoader 1s linear infinite;
// }

// @keyframes animateLoader {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }

.rocket {
  position: absolute;
  inset: 50px;
  z-index: 10;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.rocket .fa-rocket {
  position: absolute;
  top: 35%;
  color: #E54416;
  font-size: 3.5em;
  -webkit-text-stroke: 2px #000;
  animation: animateRocket 0.2s linear infinite;
}

@keyframes animateRocket {

  0%,
  100% {
    transform: translate(0, 0) rotate(-45deg);
  }

  50% {
    transform: translate(0, 3px) rotate(-45deg);
  }
}

.rocket .fa-cloud {
  position: absolute;
  top: calc(45px * var(--i));
  left: calc(40px * var(--i));
  font-size: 2em;
  color: #fff;
  -webkit-text-stroke: 2px #000;
  animation: animateCloud 1.5s linear infinite;
  animation-delay: calc(-0.5s * var(--i));
}

@keyframes animateCloud {
  0% {
    transform: translateY(calc(-35 * 5px));
  }

  100% {
    transform: translateY(calc(35 * 5px));
  }
}

@media (max-width: 540px) {
  .loader span {
    width: 80px;
    height: 80px;

    &:after {
      inset: 5px;
    }
  }

  .rocket {
    inset: 5px;

    .fa-rocket {
      font-size: 2em;
    }

    .fa-cloud {
      font-size: 1em;
    }
  }
}